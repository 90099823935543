import {useEffect, useRef} from "react";
import {useQuery} from '@tanstack/react-query';
import {createConnectTransport, createGrpcWebTransport} from "@connectrpc/connect-web";
import {createClient as createPromiseClient} from "@connectrpc/connect";
import {create} from "@bufbuild/protobuf";
import {Processor, ParseRequestSchema, StringifyRequestSchema} from "@buf/localize_localize.bufbuild_es/localize/processor_pb";
import {fromProtoSegments, toProtoSegments} from "@localizesh/sdk";
import {SegmentType, useSegmentsData} from "../provider/segmentsProvider";

const getExtensionRequestPath = (fileExtension) => {

  const extensionMapPaths = {
    xml: "android-xml",
    properties: "java-properties",
    strings: "apple-strings"
  }

  return extensionMapPaths[fileExtension] ?
      extensionMapPaths[fileExtension] : fileExtension

}

const createMyPromiseClient = (fileExtension) => {
  if(!fileExtension) return {}

  const isRstExtension = fileExtension === "rst"

  const createTransportFunc = isRstExtension ? createGrpcWebTransport : createConnectTransport

  return createPromiseClient(
      Processor,
      createTransportFunc({
        baseUrl: "https://processors.localize.sh/" + getExtensionRequestPath(fileExtension),
      })
  )
}

export const useParseRequest = (fileExtension, resource, queryKey = "queryKey") => {
  const $promiseClient = useRef(null);
  const segments = useRef(undefined);

  const {setSegments} = useSegmentsData();

  useEffect(() => {
    if(fileExtension) {
      $promiseClient.current = createMyPromiseClient(fileExtension)
    }

  }, [fileExtension, resource, queryKey])

  const parseRequest = create(ParseRequestSchema, {resource});

  const {data, ...rest} = useQuery({
    queryFn: () =>
        $promiseClient.current?.parse(parseRequest, {}).then((res) => {
          if(res.document.segments) {
            segments.current = fromProtoSegments(res.document.segments);
            setSegments(SegmentType.source, segments.current)
          }
          return res
        }),
    queryKey: ["parse", fileExtension, queryKey],
    enabled: !!fileExtension && !!resource
  })


  const {layout, metadata} = data?.document ? data?.document : {}

  return {layout, metadata, segments: segments.current, ...rest};
}

export const stringifyRequest = async (fileExtension, document) => {
  const $promiseClient = createMyPromiseClient(fileExtension)
  const {segments} = document

  const stringifyRequest = create(StringifyRequestSchema, {document: {...document, segments: toProtoSegments(segments)}})

  try {
    return $promiseClient?.stringify(stringifyRequest, {}).then(resp => resp.resource)
  } catch (err) {
    return err
  }
}